import React from "react";
import BaselineSeo from "../components/BaselineSeo";
import SearchHead from "../components/Header/Header";
import theme from "../common/theme/theme";
import {CssBaseline, Box, Button, Container, Typography} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Footer from "../components/Footer";
import Image from "../images/404.svg";

export default function Index() {
  return (
    <React.Fragment>
      <BaselineSeo></BaselineSeo>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <SearchHead />
        </Box>
        <Box bgcolor="#ecf0f2" height="100%">
        <Container
          maxWidth={false}
          style={{ textAlign: "center", marginTop: "100px", marginBottom:"100px" }}
        >
          {/* <Hidden smDown>
            <Trending trendings={props.trendings} />
          </Hidden> */}
          <img
            src={Image}
            style={{ maxWidth: "200px" }}
            alt="no-result-found"
          />
          <br />
          <br />
          <Typography variant="h5" component="h1">PAGE DOES NOT EXIST</Typography>
          <br />
          <br />
          <Button href="/" variant="outlined">
            Go to Home
          </Button>
        </Container>
      </Box>
        <Footer/>
      </ThemeProvider>
    </React.Fragment>
  );
}
